<style lang="scss" src="./style.scss"></style>
<template>
  <div
    class="w-full mt-10"
  >
    <div class="max-w-md text-center mx-auto">
      <h1
        v-if="!form.loading"
        class="mt-12 mb-16 text-2xl lg:text-3xl"
      >
        4 steps to setup your account...
      </h1>
      <div v-else>
        <h1
          class="mt-5 mb-12"
        >
          <div class="text-5xl">
            Hello,
          </div>
          <div />
          <div
            v-if="form.loadingStep > 1"
            class="text-lg font-normal mt-3"
          >
            We're setting up everything for you<span v-if="form.loadingStep > 1">.</span><span v-if="form.loadingStep > 2">.</span><span v-if="form.loadingStep > 3">.</span>
          </div>
          <div
            v-if="form.loadingStep == 5"
            class="text-lg font-normal mt-3"
          >
            Your account is almost ready to go! 👍
          </div>
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="py-5"
          >
        </h1>
      </div>
    </div>
    <div
      v-if="!form.loading"
      class="max-w-sm mx-auto bg-white shadow rounded relative mt-8 px-8 pt-2 pb-8 mb-4"
    >
      <div class="steps justify-center">
        <div class="step">
          <div class="number">
            1
          </div>
        </div>
        <div class="step">
          <div class="number">
            2
          </div>
        </div>
        <div class="step">
          <div class="number">
            3
          </div>
        </div>
        <div class="step active">
          <div class="number">
            4
          </div>
        </div>
      </div>

      <div class="text-xl font-bold text-black mb-2">
        Language
      </div>
      <p class="mb-5 block leading-normal text-grey-darker">
        Select the languages you would like to activate. These languages can be used to what you sent to the client.
      </p>

      <languages-table
        :languages="supportedLanguages"
        :defaultLanguage="form.defaultLanguage"
        :activeLanguages="form.activeLanguages"
        @onDefaultChange="handleDefaultLanguageChange"
        @onActiveChange="handleActiveLanguageChange"
      />

      <p class="mb-5 block bg-blue-washed-light leading-normal rounded py-4 px-4 text-grey-darker">
        You can always edit your languages in your settings as well as add custom languages.
      </p>

      <div class="mt-5 flex justify-end">
        <div v-if="saving">
          <img
            src="@/assets/img/icons/loader.svg"
            width="40"
          >
        </div>
        <div v-else>
          <button
            class="green-btn rounded"
            @click="$emit('create')"
          >
            Create account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyLanguage',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      supportedLanguages: [
        { code: 'nl', label: 'Dutch', isActive: false, isDefault: false },
        { code: 'en', label: 'English', isActive: true, isDefault: true },
        { code: 'de', label: 'German', isActive: false, isDefault: false },
        { code: 'it', label: 'Italian', isActive: false, isDefault: false },
        { code: 'fr', label: 'French', isActive: false, isDefault: false },
        { code: 'es', label: 'Spanish', isActive: false, isDefault: false },
        { code: 'fi', label: 'Finnish', isActive: false, isDefault: false },
        { code: 'da', label: 'Danish', isActive: false, isDefault: false },
        { code: 'sv', label: 'Swedish', isActive: false, isDefault: false },
        { code: 'no', label: 'Norwegian', isActive: false, isDefault: false },
        { code: 'pt', label: 'Portugese', isActive: false, isDefault: false },
      ]
    }
  },

  methods: {
    /**
     * Handles the default language update.
     *
     * @param {String} locale
     * @return {void}
     */
    handleDefaultLanguageChange(locale) {
      this.form.defaultLanguage = locale;
      this.form.companyLanguage = locale;
    },

    /**
     * Handles the active language update.
     *
     * @param {String} locale
     * @return {void}
     */
    handleActiveLanguageChange(locale) {
      if (this.form.activeLanguages.indexOf(locale) === -1) {
        this.form.activeLanguages.push(locale);
        this.supportedLanguages.find(l => l.code === locale).isActive = true;
      } else {
        this.form.activeLanguages.splice(this.form.activeLanguages.indexOf(locale), 1);
        this.supportedLanguages.find(l => l.code === locale).isActive = false;
      }
    }
  }
}
</script>
