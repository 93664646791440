<style lang="scss" src="./style.scss"></style>
<template>
  <div
    class="w-full mt-10"
  >
    <div class="max-w-md text-center mx-auto">
      <h1
        v-if="!form.loading"
        class="mt-12 mb-16 text-2xl lg:text-3xl"
      >
        4 steps to setup your account...
      </h1>
      <div v-else>
        <h1
          class="mt-5 mb-12"
        >
          <div class="text-5xl">
            Hello,
          </div>
          <div />
          <div
            v-if="form.loadingStep > 1"
            class="text-lg font-normal mt-3"
          >
            We're setting up everything for you<span v-if="form.loadingStep > 1">.</span><span v-if="form.loadingStep > 2">.</span><span v-if="form.loadingStep > 3">.</span>
          </div>
          <div
            v-if="form.loadingStep == 5"
            class="text-lg font-normal mt-3"
          >
            Your account is almost ready to go! 👍
          </div>
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="py-5"
          >
        </h1>
      </div>
    </div>
    <div
      v-if="!form.loading"
      class="max-w-sm mx-auto bg-white shadow rounded relative mt-8 px-8 pt-2 pb-8 mb-4"
    >
      <div class="steps justify-center">
        <div class="step">
          <div class="number">
            1
          </div>
        </div>
        <div class="step">
          <div class="number">
            2
          </div>
        </div>
        <div class="step active">
          <div class="number">
            3
          </div>
        </div>
        <div class="step">
          <div class="number">
            4
          </div>
        </div>
      </div>

      <div class="text-xl font-bold text-black mb-5">
        Invoicing details
      </div>

      <div class="mb-5 flex">
        <div class="pt-3">
          Currency
        </div>
        <div>
          <div class="select-container ml-5">
            <select v-model="form.selectedCurrency">
              <option
                v-for="currency in currencies"
                :key="currency.id"
                :value="currency.id"
              >
                {{ currency.name }}
              </option>
            </select>
          </div>
          <a class="block text-xs text-grey-darker mt-2 ml-5" id="open-intercom" href="mailto:hello@octoa.com?subject=Add new currency"> Is your currency not available? Message us.</a>

        </div>
      </div>
      <div class="flex items-center mb-5">
        <div class="w-1/2 md:w-5/12">
          Invoice valid for
          <v-help-tooltip
            :options="invoiceContent"
          />
        </div>
        <div>
          <span class="mx-3"><input
            v-model="form.invoiceValidPeriod"
            type="number"
            min="0"
            max="99"
            class="text-right w-12"
            @input="invoiceValue"
          ></span> days
        </div>
      </div>
      <div class="flex items-center mb-5">
        <div class="w-1/2 md:w-5/12">
          Proposal valid for
          <v-help-tooltip
            :options="proposalContent"
          />
        </div>
        <div>
          <span class="mx-3"><input
            v-model="form.proposalValidPeriod"
            type="number"
            min="0"
            max="99"
            class="text-right"
            @input="proposalValue"
          ></span> days
        </div>
      </div>
      <!--  <div class="flex items-center mb-5">
        <div class="w-4/12">
          Invoice number prefix
        </div>
        <div>
          <span class="mx-3"><input
            v-model="form.invoiceNumberPrefix"
            type="text"
            class="text-righ"
            placeholder="eg. AA"
            maxlength="3"
            style="width:4.4rem;"
          ></span> <span class="text-grey-semi-light">001 Preview <span>{{ form.invoiceNumberPrefix }}</span>001, <span>{{ form.invoiceNumberPrefix }}</span>002, etc.</span>
        </div>
      </div> -->

      <div class="text-xl font-bold text-black mb-2">
        Email signature
      </div>
      <p class="mb-5 block bg-blue-washed-light leading-normal rounded py-4 px-4 text-grey-darker">
        When sending proposals and invoices, this email signature will apply automatically below the content.
      </p>
      <!-- <v-editor
        :allow-token="false"
        :editor-content.sync="form.emailSignature"
      /> -->
      <v-ckeditor v-model="form.emailSignature" />
      <div class="mt-5 flex justify-end">
        <div v-if="saving">
          <img
            src="@/assets/img/icons/loader.svg"
            width="40"
          >
        </div>
        <div v-else>
          <button
            class="green-btn rounded"
            type="submit"
            @click.prevent="next"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyInvoicing',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data(){
    return {
      currencies: {},
      invoiceContent: {
        content: 'This will automatically creates a due date based on your invoice date.'
      },
      proposalContent: {
        content: 'The period that your proposals are valid after creating them.'
      },
    }
  },
  async mounted(){
    try {
      const { data } = await this.$api.get('currency').list()
      this.currencies = data.currencies
    } catch(e){
      this.$toasted.global.api_error(e)
      logException(e)
    }
  },
  methods: {
    next(){
      this.$emit('step-change', 'company-language')
    },
    invoiceValue(event){
      const value = this.form.invoiceValidPeriod
      if (value <= 360) {
        this.form.invoiceValidPeriod = value
      } else {
        this.form.invoiceValidPeriod = 360
      }
      this.$forceUpdate()
    },
    proposalValue(event){
      const value = this.form.proposalValidPeriod
      if (value <= 360) {
        this.form.proposalValidPeriod = value
      } else {
        this.form.proposalValidPeriod = 360
      }
      this.$forceUpdate()
    }
  },
}
</script>
