<template>
  <div
    class="py-16 bg-grey-light min-h-full"
  >
    <v-inner-header
      :hide-close="true"
    />
    <div
      v-if="isAuth && user.company"
      class="text-center pt-24 "
    >
      <img
        src="@/assets/img/octoa.svg"
        width="60"
        class="my-10"
      >
      <h1>Hello, {{ user.company.name }}.</h1>
      <br>
      <p class="leading-normal">
        You have already logged in.<br>
        Go to your dashboard
        <router-link
          to="/"
          class="underline text-green"
        >
          here
        </router-link>
        or
        <a
          href="#"
          @click="logout"
        > logout</a>.
      </p>
    </div>
    <div
      v-if="isAuth && !user.company"
      class="w-full mt-10 px-4"
    >
      <company-info
        v-if="step === 'company-info'"
        :form.sync="form"
        :loading.sync="form.loading"
        @step-change="onStepChange"
      />
      <company-tax
        v-if="step === 'company-tax'"
        :form.sync="form"
        :loading.sync="form.loading"
        @step-change="onStepChange"
      />
      <company-invoicing
        v-if="step === 'company-invoicing'"
        :form.sync="form"
        :saving.sync="form.saving"
        :loading.sync="form.loading"
        @step-change="onStepChange"
      />
      <company-language
        v-if="step === 'company-language'"
        :form.sync="form"
        :saving.sync="form.saving"
        :loading.sync="form.loading"
        @create="onRegisterCompany"
      />
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import storage from '@/storage'
import CompanyInfo from '@/views/RegisterSteps/CompanyInfo'
import CompanyTax from '@/views/RegisterSteps/CompanyTax'
import CompanyInvoicing from '@/views/RegisterSteps/CompanyInvoicing'
import CompanyLanguage from '@/views/RegisterSteps/CompanyLanguage'

export default {
  name: 'Register',
   components: {
    CompanyInfo,
    CompanyTax,
    CompanyInvoicing,
    CompanyLanguage
  },
  data(){
    return {
      stepNumber: 1,
      step: 'company-info',
      form: {
        userId: null,
        name: '',
        email: '',
        password: '',
        companyName: '',
        companyAddress: '',
        companyEmail: '',
        companyCountry: '',
        companyPostalCode: '',
        companyCity: '',
        companyPhone: '',
        companyLanguage: 'en',
        defaultLanguage: 'en',
        activeLanguages: ['en'],
        selectedIndustry: 'Wedding',
        selectedCurrency: 1,
        selectedTimezone: 'Europe/Amsterdam',
        useTax: false,
        mainTaxRate: '21',
        includeTaxInPrice: false,
        invoiceValidPeriod: '14',
        proposalValidPeriod: '30',
        invoiceNumberPrefix: '',
        emailSignature: '',
        saving: false,
        loading: false,
        loadingStep: 1,
        refcode: '',
        ipAddress: '',
        userAgent: navigator.userAgent,
      },
      isAuth: auth.check(),
      user: auth.user()
    }
  },
  async mounted() {

    this.form.userId = this.user.me.id
    this.form.companyEmail = this.user.me.email

    if(this.user.me.referral_code){
      this.form.refcode = this.user.me.referral_code
    }

    fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
      this.form.ipAddress = ip
    })
  },
  methods: {
    onStepChange(step) {

      if(step == 'company-info' && this.form.companyEmail == ''){
        this.form.companyEmail = this.form.email
      }

      if(this.stepNumber == 1){
        this.$gtag.event('register', {
          'event_category': 'company',
          'event_label': 'step',
          'value': 1,
        })
      }

      if(this.stepNumber == 2){
        this.$gtag.event('register', {
          'event_category': 'company',
          'event_label': 'step',
          'value': 2,
        })
      }

      if(this.stepNumber == 3){
        this.$gtag.event('register', {
          'event_category': 'company',
          'event_label': 'step',
          'value': 3,
        })
      }

      this.step = step
      this.stepNumber++
      this.$router.push({name: 'RegisterCompany', query: {  step: this.stepNumber } })

    },
    async logout() {
      this.$toasted.clear()

      try {
        this.$store.dispatch('auth/logout')
      } catch(e) {
        this.$toasted.global.api_error(e)
      }

      window.location.reload(true)
    },

    async onRegisterCompany(){

      this.form.saving = true
      this.form.loading = false
      this.$toasted.clear()

      // if(this.form.emailSignature == ''){
      //   this.$toasted.global.general_error({
      //     message : 'Please enter a email signature.'
      //   })

      //   this.form.saving = false
      //   return false
      // }

      try {
        await this.$store.dispatch('auth/createCompany', this.form)

        this.form.loading = true
        this.form.saving = false

        await auth.refreshUser()

        this.$gtag.event('register', {
          'event_category': 'company',
          'event_label': 'finish',
          'value': 4,
        })

        this.$gtag.set({
          'userId': `${auth.user().me.company.id}`,
        })

        setTimeout(() => this.form.loadingStep = 2, 500)
        setTimeout(() => this.form.loadingStep = 3, 2000)
        setTimeout(() => this.form.loadingStep = 4, 3500)
        setTimeout(() => this.form.loadingStep = 5, 4500)

        this.$gtag.event('conversion', {
          'send_to': 'AW-370689901/QG9PCITJs4YYEO2O4bAB'
        })

        if (auth.user().me.discount_code) {
          setTimeout(() => this.$router.replace({ name: 'SettingsPlanBillingWithoutHeader'}), 5500)
        } else {
          setTimeout(() => this.$router.replace({name: 'Dashboard', query: {page: 'intro'}}), 5500)
        }

      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
  },
}
</script>
