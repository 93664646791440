<style lang="scss" src="./style.scss"></style>
<template>
  <div
    class="w-full mt-10"
  >
    <div class="max-w-md text-center mx-auto">
      <h1 class="mt-12 mb-16 text-2xl lg:text-3xl">
        4 steps to setup your account...
      </h1>
    </div>
    <form
      class="max-w-sm mx-auto bg-white shadow rounded relative mt-8 px-8 pt-2 pb-8 mb-4"
      @submit.prevent="next"
    >
      <div class="steps justify-center">
        <div class="step active">
          <div class="number">
            1
          </div>
        </div>
        <div class="step">
          <div class="number">
            2
          </div>
        </div>
        <div class="step">
          <div class="number">
            3
          </div>
        </div>
        <div class="step">
          <div class="number">
            4
          </div>
        </div>
      </div>
      <div class="text-xl font-bold text-black mb-5">
        Some company information
      </div>
      <div class="input-group">
        <label>Company name</label>
        <input
          id="name"
          ref="name"
          v-model="form.companyName"
          v-validate="{ required: true }"
          type="text"
          class="w-full"
          name="name"
        >
        <span class="error">{{ errors.first('name') }}</span>
      </div>
      <div class="input-group">
        <label>Company email address</label>
        <input
          id="email"
          v-model="form.companyEmail"
          v-validate="{ required: true, email: true}"
          type="text"
          class="w-full"
          name="email"
        >
        <span class="error">{{ errors.first('email') }}</span>
      </div>
      <div class="input-group">
        <label>Address</label>
        <input
          id="address"
          v-model="form.companyAddress"
          v-validate="{ required: true }"
          type="text"
          class="w-full"
          name="address"
        >
        <span class="error">{{ errors.first('address') }}</span>
      </div>
      <div class="input-group">
        <label>Postal code</label>
        <input
          id="postal_code"
          v-model="form.companyPostalCode"
          type="text"
          class="w-full"
          name="postal_code"
        >
        <span class="error">{{ errors.first('postal_code') }}</span>
      </div>
      <div class="input-group">
        <label>City</label>
        <input
          id="city"
          v-model="form.companyCity"
          v-validate="{ required: true }"
          type="text"
          class="w-full"
          name="city"
        >
        <span class="error">{{ errors.first('city') }}</span>
      </div>

      <div class="input-group">
        <label for="country">Country </label>
        <div class="select-container w-full">
          <select
            v-model="form.companyCountry"
            v-validate="'required'"
            name="country"
            :tab-index="0"
          >
            <option value="">
              Select a country
            </option>
            <option
              v-for="(item, index) in countriesList"
              :key="index"
              :value="item.name"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <span class="error">{{ errors.first('country') }}</span>
      </div>
      <!-- <div class="input-group">
        <label>Phone number</label>
        <input
          id="phone"
          v-model="form.companyPhone"
          v-validate="{ required: true }"
          type="text"
          class="w-full"
          name="phone"
        >
        <span class="error">{{ errors.first('phone') }}</span>
      </div> -->
      <!-- <div class="input-group">
        <label>Currency</label>
        <div class="select-container w-full">
          <select v-model="form.selectedCurrency">
            <option
              v-for="currency in currencies"
              :key="currency.id"
              :value="currency.id"
            >
              {{ currency.name }}
            </option>
          </select>
        </div>
      </div> -->
      <div class="input-group">
        <label for="timezone"> Timezone </label>
        <div class="select-container w-full">
          <select
            v-model="form.selectedTimezone"
            v-validate="'required'"
            name="timezone"
          >
            <option value="">
              Select a timezone
            </option>
            <option
              v-for="(item, key) in timezoneList"
              :key="key"
              :value="key"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div class="input-group">
        <label>Industry you work in</label>
        <div class="select-container w-full">
          <select v-model="form.selectedIndustry">
            <option value="Wedding">
              Wedding
            </option>
            <option value="Consultant">
              Consultant
            </option>
            <option value="Copywriter">
              Copywriter
            </option>
            <option value="Advertisting">
              Advertisting
            </option>
            <option value="Creative">
              Creative
            </option>
            <option value="Other">
              Other
            </option>
          </select>
        </div>
      </div>
      <div class="mt-5 flex justify-end">
        <button
          class="green-btn rounded w-32"
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'CompanyInfo',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data(){
    return {
      currencies: {},
      countriesList: [],
      timezone: '',
      timezoneList: [],
    }
  },
  async mounted(){
    await this.getCountriesList()
    await this.getTimeZoneList()
  },
  methods: {
    next(){
      this.$validator.validate().then(result => {
        if(result){
          this.$emit('step-change', 'company-tax')
         }
      })
    },
    async getCountriesList() {
      try {
        const { data } = await this.$api.get('country').all()
        this.countriesList = data.countries
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    async getTimeZoneList() {
      try {
        const { data } = await this.$api.get('timezone').all()
        this.timezoneList = data.timezone
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    async doSave(){
      this.loading = true
      this.$toasted.clear()

      try {
        await this.$store.dispatch('auth/createCompany', this.form)
        setTimeout(() => this.$router.replace({ name: 'RegisterWizard' }), 0)
      } catch (e) {
        this.$toasted.global.api_error(e)
      }

      this.loading = false
    },
  },
}
</script>
