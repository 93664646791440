<style lang="scss" src="./style.scss"></style>
<template>
  <div
    class="w-full mt-10"
  >
    <div class="max-w-md text-center mx-auto">
      <h1 class="mt-12 mb-16 text-2xl lg:text-3xl">
        4 steps to setup your account...
      </h1>
    </div>
    <form
      class="max-w-sm mx-auto bg-white shadow rounded relative mt-8 px-8 pt-2 pb-8 mb-4"
      @submit.prevent="next"
    >
      <div class="steps justify-center">
        <div class="step">
          <div class="number">
            1
          </div>
        </div>
        <div class="step active">
          <div class="number">
            2
          </div>
        </div>
        <div class="step">
          <div class="number">
            3
          </div>
        </div>
        <div class="step">
          <div class="number">
            4
          </div>
        </div>
      </div>
      <div class="text-xl font-bold text-black mb-2">
        Tax information
      </div>
      <p class="leading-normal mb-3">
        Before we can get started we need to know a little bit about your business. You can still add/change everything in your settings.
      </p>
      <v-checkbox
        id="useTax"
        v-model="form.useTax"
        :checked="form.useTax"
        class="mt-2 mb-5"
        label="Do you apply tax to your projects?"
      />

      <div v-show="form.useTax">
        <p class="text-base leading-normal">
          What's your most used VAT rate?
        </p>
        <p class="note">
          You can change and add more Tax classes in your settings afterwards. And determine per proposal or invoice if you charge tax.
        </p>
        <div class="mt-5 flex items-center">
          <div class="">
            VAT
          </div>
          <div class="mx-2 w-18">
            <input
              id="mainTaxRate"
              v-model="form.mainTaxRate"
              name="mainTaxRate"
              type="number"
              placeholder=""
            >
          </div>
          <div class="">
            %
          </div>
        </div>
        <!-- <hr class="my-6">
        <div class="text-xl font-bold text-black mb-2">
          Tax calculation
        </div>
        <p class="text-base leading-normal">
          Are your default prices inclusive of the tax or is the tax added on top of your default prices. (This can still be changed per proposal and invoice)
        </p>
        <span class="mt-4 mb-5 block bg-blue-washed-light leading-normal rounded py-4 px-4 text-grey-darker">*Various countries and cases have a different way of calculating tax. For example, in Europe when servicing consumers your prices have to be including tax. When in the US the tax is added on top of the prices.</span>

        <div class="mb-3">
          <v-checkbox
            id="includeTaxInPrice"
            v-model="form.includeTaxInPrice"
            label="Tax on top of prices"
          />
        </div> -->
      </div>
      <div class="mt-5 flex justify-end">
        <button
          class="green-btn rounded w-32"
          type="submit"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'CompanyInfo',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data(){
    return {
    }
  },
  async mounted(){
  },
  methods: {
    next(){
      this.$emit('step-change', 'company-invoicing')
    },
  },
}
</script>
